import React from 'react';
import loadable from '@loadable/component';
// import {
// 	useQueryParams,
// 	BooleanParam,
// 	NumberParam,
// 	StringParam,
// } from 'use-query-params';
// import Calculator from './Calculator';
// import EmailForm from './EmailForm/EmailForm';
// import MarketoForm from './MarketoForm/MarketoForm';
// import PdfView from './PdfView/PdfView';

const Calculator = loadable(() => import('./Calculator'), { ssr: false });

const RoiCalculator = () => {
	return (
		<>
			<Calculator />
			{/*<EmailForm/>*/}
			{/*{displayPrintView ? (*/}
			{/*  <PdfView*/}
			{/*    calculatedValues={calculatedValues}*/}
			{/*    formValues={formValues}*/}
			{/*    product={product}*/}
			{/*  />*/}
			{/*) : null}*/}
			{/*<MarketoForm*/}
			{/*  baseUrl="//app-sj02.marketo.com"*/}
			{/*  munchkinId="343-MGE-042"*/}
			{/*  formId={formIds[product]}*/}
			{/*/>*/}
		</>
	);
};

export default RoiCalculator;
